<template>
<div>
    <v-container mt-10 mb-10></v-container>
    <v-container fluid class="pa-0 backgroundFooterPrograma">
        <v-layout>
            <v-flex xs12 md10 class="mx-auto pt-3 pb-3 ">
                <v-layout wrap v-if="programa == 'Despensa'">
                    <v-flex xs4 md2 class="offset-md-1 align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/productos/promass.png" />
                    </v-flex>
                    <v-flex xs4 md2 class="align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/productos/club.png" />
                    </v-flex>
                    <v-flex xs4 md2 class="align-self-center pa-2">
                        <v-img max-width="90" class="mx-auto" src="@/assets/img/footer/productos/hb.png" />
                    </v-flex>
                    <v-flex xs4 md2 class="align-self-center pa-2">
                        <v-img max-width="120" class="mx-auto" src="@/assets/img/footer/productos/panam.png" />
                    </v-flex>
                    <v-flex xs4 md2 class="align-self-center pa-2">
                        <v-img max-width="120" class="mx-auto" src="@/assets/img/footer/productos/chedraui.png" />
                    </v-flex>
                </v-layout>
                <v-layout wrap v-if="programa == 'Remesa'">
                    <v-flex xs6 md2 class="align-self-center pa-2 offset-md-1">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/productos/promass.png" />
                    </v-flex>
                    <v-flex xs6 md2 class="align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/productos/club.png" />
                    </v-flex>
                    <v-flex xs6 md2 class="align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/productos/chubb.png" />
                    </v-flex>
                    <v-flex xs6 md2 class="align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/productos/wta.png" />
                    </v-flex>
                    <v-flex xs12 md2 class="align-self-center pa-2">
                        <v-img max-width="120" class="mx-auto" src="@/assets/img/footer/productos/chedraui.png" />
                    </v-flex>
                </v-layout>
                <v-layout wrap v-if="programa == 'Assist'">
                    <v-flex xs6 md4 class="align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/productos/promass.png" />
                    </v-flex>
                    <v-flex xs6 md4 class="align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/productos/club.png" />
                    </v-flex>
                    <v-flex xs6 md4 class="align-self-center pa-2">
                        <v-img max-width="120" class="mx-auto" src="@/assets/img/footer/productos/chedraui.png" />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</div>
</template>

<script>
export default {
    name: "FooterPrograma",
    props: ["programa"]
}
</script>
