<template>
<div>
    <v-container fluid class="pa-0 parentDiv">
        <v-img class="bannerProducto" :src="require('@/assets/img/despensa/'+banner+'.png')" />
        <RouterLink to="/">
            <v-img class="backHome" max-width="40" :src="require('@/assets/img/btnHome.png')" />
        </RouterLink>
    </v-container>
    <v-container mt-10></v-container>
    <v-container>
        <v-img max-width="350" class="mx-auto" :src="require('@/assets/img/logos/LogoDespensa.png')" />
    </v-container>
    <v-container mt-4 mb-4></v-container>
    <v-container>
        <p class="azul font-19 text-center">
            ¡Protección Despensa te da la tranquilidad que necesitas con Asistencias y un Seguro que protege a los tuyos en caso de que faltes!
        </p>
        <p class="naranja font-weight-bold font-25 text-center">
            ¡Conoce los beneficios!
        </p>
        <v-container mt-4 mb-4></v-container>
        <v-layout wrap>
            <v-flex xs12 md4 class="pa-5">
                <Bloque liga="" textoLiga="" :beneficios="beneficiosMiBienestar" img="despensa/MiBienestar.png" titulo="Mi Bienestar" descripcion="Te orientamos sobre temas relacionados con tu salud y la de tu familia." />
            </v-flex>
            <v-flex xs12 md4 class="pa-5">
                <Bloque liga="" textoLiga="" :beneficios="beneficiosMiAsistencia" img="despensa/MiAsistencia.png" titulo="Mi Asistencia" descripcion="Te brindamos orientación vía telefónica en caso de que necesites asesoría legal, apoyo escolar, atención médica veterinaria o enlace en casos de emergencia." />
            </v-flex>
            <v-flex xs12 md4 class="pa-5">
                <Bloque liga="" textoLiga="" :beneficios="beneficiosMiHogar" img="despensa/Mihogar.png" titulo="Mi Hogar" descripcion="Si tienes algún imprevisto en casa, te apoyamos con el fin de limitar y controlar los daños materiales." />
            </v-flex>
        </v-layout>
        <v-layout wrap>
            <v-flex xs12 md6>
                <v-layout wrap>
                    <v-flex xs12 md7 class="pa-2 mx-auto">
                        <Bloque liga="" textoLiga="" :beneficios="beneficiosMiCamino" img="despensa/MiCamino.png" titulo="Mi Camino" descripcion="Si tu automóvil presenta alguna avería menor, como descarga de batería, ponchadura de llanta, o falta de gasolina, te enviaremos a un prestador de servicios para realizar el servicio." />
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 md6>
                <v-layout wrap>
                    <v-flex xs12 md7 class="pa-2 mx-auto">
                        <Bloque liga="miseguro" textoLiga="Cómo uso el servicio de Mi Seguro" :beneficios="beneficiosMiSeguro" img="despensa/MiSeguro.png" titulo="Mi Seguro" descripcion="En caso de que el Titular llegue a fallecer por un accidente, se hará un pago por <span class='azul font-weight-bold'>$10,000.</span>" />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-container mt-4 mb-4></v-container>
        <p class="naranja font-weight-bold font-18 text-center">
            No esperes más y ¡contrata tu Protección Despensa ahora mismo!
        </p>
        <v-container mt-4 mb-4></v-container>
        <v-layout wrap>
            <v-flex xs12 md4 class="text-center pa-4 mx-auto">
                <v-btn color="#0c55a6" width="200" height="35" :href="'pdf/despensa/CondicionesGenerales.pdf'" download="CondicionesGeneralesDespensa.pdf" class="btnNoMayuscula" dark small>Condiciones Generales</v-btn>
            </v-flex>
        </v-layout>
        <v-container mt-4 mb-4></v-container>
        <p class="text-center line-height">
            <span class="azul font-18">Para más información llámanos al:</span>
            <br>
            <span class="font-weight-bold naranja font-25"><a class="noList naranja" href="tel:5554816021">55 54 81 60 21</a></span>
            <br>
            <span class="azul font-12">Horario de atención: las 24 horas de los 365 días del año</span>
        </p>
    </v-container>
    <Footer programa="Despensa" />
</div>
</template>

<script>
import Bloque from "@/components/BloqueBeneficio.vue";
import Footer from "@/components/FooterPrograma.vue";

export default {
    name: "Despensa",
    components: {
        Bloque,
        Footer
    },
    data() {
        return {
            imgFooter: '',
            beneficiosMiBienestar: ["Línea médica", "Línea psicológica", "Línea nutricional", "Línea dental", "Consulta virtual"],
            beneficiosMiAsistencia: ["Línea legal", "Línea escolar", "Línea médica veterinaria", "Enlace para emergencias"],
            beneficiosMiHogar: ["Plomero", "Electricista", "Cerrajero", "Vidriero"],
            beneficiosMiCamino: ["Cambio de llanta", "Envío de gasolina", "Paso de corriente", "Grúa"],
            beneficiosMiSeguro: []
        }
    },
    computed: {
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerDespensaMovil';
                case 'sm':
                    return 'BannerDespensaMovil';
                case 'md':
                    return 'BannerDespensa';
                case 'lg':
                    return 'BannerDespensa';
                case 'xl':
                    return 'BannerDespensa';
            }
        }
    }
}
</script>
