<template>
<div>
    <v-layout wrap>
        <v-flex xs12 md12>
            <v-img max-width="50" class="mx-auto" :src="require('@/assets/img/' + img)" />
        </v-flex>
        <v-flex xs12 md12 mt-3>
            <p class="naranja font-20 font-weight-bold subrayado text-center">
                {{ titulo }}
            </p>
        </v-flex>
        <v-flex xs12 md12 class="text-center">
            <p class="negro fnt-weight-bold text-justify" v-html="descripcion">
            </p>
        </v-flex>
        <v-flex xs12 md12 mt-2 v-if="beneficios.length > 0">
            <ul>
                <li class="azul" v-for="beneficio in beneficios" :key="beneficio" v-html="beneficio"></li>
            </ul>
        </v-flex>
        <v-flex xs12 md12 mt-2 v-if="liga != ''" class="text-center">
            <router-link :to="'/'+liga" target= '_blank'>
                {{ textoLiga }}
            </router-link>
        </v-flex>
    </v-layout>
</div>
</template>

<script>
export default {
    name: "BloqueBeneficio",
    props: ["img", "titulo", "descripcion", "beneficios", "liga", "textoLiga"]
}
</script>
